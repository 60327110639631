<template>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Subscribe"
};
</script>
<style scoped>
.container {
  max-width: 1140px;
}
</style>
